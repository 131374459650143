import _ from 'lodash'

import useModel from '../../store/useModel'

const useOrderDiscount = (order_id) => {
  const [order, state] = useModel('order', [order_id], { single: true, populate: ['service.contract', 'transaction.codepromo'] })
  const driver_discount = order?.service?.contract?.driver_discount
  const customer_discount = order?.service?.contract?.customer_discount
  const codepromo_amount = order?.transaction?.codepromo?.amount

  const driver_discount_percent = (driver_discount || 0) * 100
  const customer_discount_percent = (customer_discount || 0) * 100

  return [{
    driver_discount,
    customer_discount,
    driver_discount_percent,
    customer_discount_percent,
    codepromo_amount
  }, state]
}

export default useOrderDiscount
